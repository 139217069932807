import { Menu, Transition } from "@headlessui/react";
import cntl from "cntl";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { AccountCircle } from "~/components/assets/icons/account-circle";
import { User } from "~/components/hooks/useUser";
import { logout } from "~/lib/utils/auth";
interface ProfileMenuProps {
  user: User;
}

interface UserNavigationItem {
  text: string;
  onClick: () => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ user }) => {
  const router = useRouter();
  const getUserNavigation = (): UserNavigationItem[] => {
    const userNav: UserNavigationItem[] = [
      { text: "My Profile", onClick: () => router.push("/profile") },
      {
        text: "Sign out",
        onClick: () => logout(router),
      },
    ];
    return userNav;
  };

  return (
    <div className="max-w-full">
      <Menu as="div" className="relative max-w-full">
        <div>
          <Menu.Button className="flex max-w-full rounded-md border border-lightGrey bg-lighterGrey hover:bg-lightGrey focus:outline-none">
            <span className="sr-only">Open user menu</span>
            <div className="flex max-w-full items-center space-x-2 px-2 py-2 text-darkBlue sm:px-3">
              <AccountCircle className="h-6 w-6 flex-[1_0_auto] fill-current" />
              <span
                className={`hidden overflow-hidden text-ellipsis whitespace-nowrap sm:block`}
              >
                {user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : "My Account"}
              </span>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 flex w-48 origin-top-right flex-col rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {getUserNavigation().map((item) => (
              <Menu.Item key={item.text}>
                {() => (
                  <div
                    className={cntl`
                    block cursor-pointer px-4 py-2 text-sm
                    text-darkBlue
                    hover:text-darkGreen
                    hover:underline
                  `}
                    onClick={item.onClick}
                  >
                    {item.text}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
