import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import cntl from "cntl";
import { useRouter } from "next/router";
import React from "react";
import { SelectChoice } from "../forms/fields/SelectItem";
import { CapabilityType, useUser } from "../hooks/useUser";

interface NavDropdownProps {
  selection: "funding" | "tracks" | "payroll";
}

const navChoices: {
  id: string;
  text: string;
  requiredCapability?: CapabilityType;
}[] = [
  { id: "funding", text: "FUNDING" },
  { id: "tracks", text: "TRACKS" },
  { id: "payroll", text: "PAYROLL", requiredCapability: "PAYROLL" },
];

const NavDropdown: React.FC<NavDropdownProps> = ({ selection }) => {
  const router = useRouter();
  const { data: user } = useUser();

  const selectedChoice = navChoices.find((choice) => choice.id === selection);
  return (
    <Listbox
      as="div"
      value={selectedChoice}
      onChange={(choice: SelectChoice) =>
        choice.id == "tracks"
          ? router.push("/tracks")
          : choice.id == "payroll"
          ? router.push("/payroll")
          : router.push("/")
      }
      className="relative inline-block text-left"
    >
      <div>
        <Listbox.Button className="group inline-flex items-center justify-center rounded border border-borderGrey text-base font-medium text-gray-700 hover:text-gray-900">
          <div className="ml-1.5 flex items-center gap-1  px-1.5 py-0.5 text-base font-semibold tabular-nums text-gray-700">
            <span className="">{selectedChoice?.text}</span>
          </div>

          <ChevronDownIcon
            className="ml-1 mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Listbox.Button>
      </div>
      <Listbox.Options className="absolute mt-2 h-40 overflow-auto rounded-md bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
        {navChoices.map((choice) => {
          if (
            choice.requiredCapability != null &&
            !user?.capabilities?.includes(choice.requiredCapability)
          ) {
            return null;
          }
          return (
            <Listbox.Option
              key={choice.id}
              value={choice}
              className={({ active }) =>
                `${active ? "underlinett text-darkGreen" : "text-gray-900"} 
                        relative ml-0 cursor-pointer select-none list-none`
              }
            >
              {({ active, selected }) => (
                <div
                  className={cntl`
                    flex
                    ${selected ? "bg-lightGreen text-darkGreen" : ""}
                    w-full
                    rounded-md
                    p-2`}
                >
                  <span>{choice.text}</span>
                </div>
              )}
            </Listbox.Option>
          );
        })}
      </Listbox.Options>
    </Listbox>
  );
};

export default NavDropdown;
