import cntl from "cntl";
import Link, { LinkProps } from "next/link";
import React from "react";

interface LinkButtonProps extends LinkProps {
  variant?: "Primary" | "Secondary";
  color?: "darkGreen" | "petrolGreen";
  backgroundTransparent?: boolean;
  size?: "sm" | "md" | "lg" | "full";
  disabled?: boolean;
  external?: boolean;
  newTab?: boolean;
  children?: React.ReactNode;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  size = "sm",
  color = "darkGreen",
  variant = "Primary",
  href,
  passHref,
  backgroundTransparent = false,
  children,
  disabled = false,
  external = false,
  newTab = false,
}) => {
  const buttonCN = cntl`
    inline-flex
    items-center
    ${size === "md" ? "px-12" : "px-6"}
    ${size === "full" ? "w-full" : size === "lg" ? "w-72" : "sm:w-auto"}
    ${size === "lg" ? "text-xl" : "text-sm"}
    ${size === "lg" ? "h-14" : "h-auto"}
    w-full
    justify-center
    py-2
    border
    font-medium
    rounded-md
    shadow-sm
    no-underline
    ${
      disabled
        ? "bg-lightGrey text-disabledGrey"
        : variant === "Primary"
        ? cntl`
          ${
            color === "darkGreen"
              ? "bg-darkGreen focus:ring-darkGreen"
              : "bg-petrolGreen focus:ring-petrolGreen"
          }
          text-white
          hover:bg-rolloverGreen
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
        `
        : cntl`
          border-darkGreen
          ${backgroundTransparent ? "bg-transparent" : "bg-white"}
          text-${color}
          border-${color}
          ${
            color === "darkGreen"
              ? "text-darkGreen border-darkGreen"
              : "text-petrolGreen border-petrolGreen"
          }
          hover:bg-lightGreen
        `
    }
  `;

  if (external) {
    return (
      <a
        className={buttonCN}
        href={href.toString()}
        {...(newTab && { target: "_blank", rel: "noreferrer" })}
      >
        {children}
      </a>
    );
  }

  return (
    <Link href={href} passHref={passHref}>
      <a className={buttonCN} onClick={(e) => e.stopPropagation()}>
        {children}
      </a>
    </Link>
  );
};

export default LinkButton;
