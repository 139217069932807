import Link from "next/link";
import { FarmRaiseIcon } from "../assets/logos/farmraise-icon";
import { FarmRaiseLogo } from "../assets/logos/farmraise-logo";

interface LogoLinkProps {
  style: "Responsive" | "FullLogo" | "IconOnly";
}

export const LogoLink: React.FC<LogoLinkProps> = ({ style }) => {
  return (
    <Link href="/" passHref>
      <div className="cursor-pointer" role="link" aria-label="FarmRaise Home">
        <Logo style={style} />
      </div>
    </Link>
  );
};

const Logo: React.FC<LogoLinkProps> = ({ style }) => {
  switch (style) {
    case "IconOnly":
      return <FarmRaiseIcon />;
    case "FullLogo":
      return <FarmRaiseLogo />;
    case "Responsive":
      return (
        <>
          <div className="hidden sm:block">
            <FarmRaiseLogo />
          </div>
          <div className="block sm:hidden">
            <FarmRaiseIcon />
          </div>
        </>
      );
  }
};
